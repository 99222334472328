import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Screen from '../../components/layout/Screen';
import { useStyles } from './styles';
import Spacer from '../../components/Spacer';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">
        Terms & Conditions
      </Typography>
      <Spacer height={'20'} />
      <Typography variant="body1">
        Please Read Carefully<br />
        1.The Promoter<br />
        The promoter is: Value Focused Project Corp, operated by VFP, whose registered in the state of Georgia, USA.<br />
        2.The Competition<br />
        2.1. These terms and conditions apply to all competitions/raffles listed on the Promoter’s website at Dapp.voteforpedro.io (the “Website”)<br />
        2.2. The Promoter may offer different formats to the competitions/raffles. Subject to clause 2.3, an entry fee is payable each time you enter.<br />
        2.3. To be in with a chance of winning, everyone who enters the competition (an “Entrant”) will be required to pay an entry fee set by the Promoter for that event.<br />
        3.How to enter<br />
        3.1. The competition/raffle will run from and including the opening and closing dates specified on the Website or until raffle amount raised. These dates shall be referred to as the “Opening Date” and “Closing Date” respectively. All times and dates referred to are the times and dates in Eastern Standard Time.<br />
        3.2. If it is absolutely necessary to do so, the Promoter reserves the right to change the Opening and Closing Dates. If the Promoter does change the Opening Date and/or the Closing Date of a competition, the new details will be displayed on the Website. The Promoter can extend the Closing Date to sell more entries required to meet the raffle amount.<br />
        3.3. All competition/raffle entries must be received by the Promoter by no later than the specified time on the Closing Date. All competition entries received after the specified time on the Closing Date are automatically disqualified and no refunds will be given.<br />
        3.4. [The maximum number entries to the competition/raffle will be stated on the Website. The number of entries you are able to make may be limited if the maximum number of entries is reached.]<br />
        3.5. Entrants can enter the competition/raffle as many times as they wish [until the maximum number of entries have been received.]<br />
        3.6. To enter the competition:<br />
        (a) go to the Website and view the live Competition/raffle, if there is one;<br />
        (b) choose which one you'd like a chance to win.<br />
        (c) purchase the required number of entries; then<br />
        (d) when you have purchased your entries, submit and confirm your transaction through your connected Solana Wallet.<br />
        3.7. All entries must be submitted via the Solana network. Other Tokens coins used will automatically be disqualified and no refund will be given.<br />
        3.8. Our Website/DApp will send confirmation that your entry has been received and your allocated random ticket number(s). [and confirm on the Solana Blockchain and our Website/DApp].<br />
        3.9. The Promoter will not accept responsibility for competition/raffle entries that are not successfully completed, are lost or are delayed regardless of cause, including, for example, as a result of any equipment failure, technical malfunction, systems, satellite, Solana network, server, computer hardware or software failure of any kind.<br />
        3.10. By purchasing entries and submitting a competition/raffle entry, you are entering into a smart contract with the Promoter's DApp and are agreeing to be bound by these terms and conditions.<br />
        3.11. You may enter the competition as many times as permissable.<br />
        (a) by entering the competition, you are confirming that you are eligible to enter and accept these terms and conditions;<br />
        (b) your entry must be received by the Promoter prior to the Closing Date. Entries received after the Closing Date will not be entered into the random draw. Proof of posting does not guarantee that you will be entered into the random draw;<br />
        (c) if the number of entries received reaches any cap or limit before your free entry is received, you will not be entered into the random draw.<br />
        (d) The confirmation ticket is entered as a saved contract, tickets will be visible to see in our website/DApp.<br />
        4.Choosing a winner<br />
        4.1. All Entrants will be placed into a draw and the winner will be chosen automatically by the smart contract randomizer draw generator. Winners in any event, will be paid out within [7] days of the Closing Date (“Draw Date“).<br />
        4.2. All Entrants will have their wallet addresses and entry numbers included on a spreadsheet which may be published on the Website/DApp and may be visible during the live draw and after the live draw ends.<br />
        4.3. [For help with entries, please contact us on admin@voteforpedro.io<br />
        5.Eligibility<br />
        5.1. The competitions / Raffle events are open to all Solana Holders/users.<br />
        5.2. By entering the competition/raffle, you confirm that you are eligible to do so and eligible to claim any prize you may win. The Promoter may require you to provide proof that you are eligible to enter the competition and claim the prize. If you fail to provide the Promoter with any such proof or other information that they may require within a reasonable time, you may be disqualified from the competition.<br />
        5.3. The Promoter will not accept competition entries that are:<br />
        (a) automatically generated by computer; or<br />
        (b) incomplete.<br />
        5.4. The Promoter reserves all rights to disqualify you if your conduct is contrary to the spirit or intention of the prize competition. This includes if you are rude or abusive to the Promoter or anyone associated with them.<br />
        5.5. No refunds of the entry fee will be given in any event, including;<br />
        (a) if, following your entry into the competition, you subsequently find out that you are not eligible to enter the competition or claim the Prize;<br />
        (b) if, following your entry into the competition the eligibility criteria for entering the competition or claiming the Prize changes and you are no longer eligible; or<br />
        (c) if you are disqualified from the competition by the Promoter for any reason.<br />
        5.6. If the Entrant engages in:<br />
        (a) any form of fraud (actual or apparent);<br />
        (b) fraudulent misrepresentation;<br />
        (c) fraudulent concealment;<br />
        (d) hacking or interference with the proper functioning of the Website; or<br />
        (e) amending, or unauthorised use of, any of the code that constitutes the website<br />
        all of their entries will be declared void, no refunds will be given and they may be prevented from participating in any future competitions.<br />
        6.The prize<br />
        6.1. The prize for each competition is described on the Website (the “Prize”). Details of the Prize are, to the best of the Promoter’s knowledge, information and belief, correct as at the Opening Date.<br />
        6.2. Prizes are subject to availability. The Promoter reserves the right to substitute any prize with a prize of equal or greater value. If any details of the Prize change, the Promoter will endeavour to update the Website as soon as reasonably possible.<br />
        6.3. The Promoter makes no representations and gives no warranties about the Prize, its value, its condition or any other information provided on the Website. The Promoter makes no representations and gives no warranties that the information provide on the Website is accurate, complete or up to date.<br />
        6.4. The Prize may be supplied by a third-party supplier (the “Supplier”). Details of the Supplier (if any) will be provided on the Website.<br />
        6.5 The Promoter has no responsibility for the Prize(s) once it has been delivered/collected.<br />
        6.6 The Promoter guarantees that all tech prizes are brand new for each draw but cannot guarantee Prizes will have a warranty for any breakages after they are handed over.<br />
        6.7.[The Promoter reserves the right to substitute the Prize for an alternative cash prize (“Cash Prize”) in the following circumstances:<br />
        (a) the Prize becomes unavailable;<br />
        (b) other circumstances beyond the reasonable control of the Promoter make it necessary to do so.<br />
        6.6. The prize is not negotiable or transferable.<br />
        7.Winners<br />
        7.1. The decision of the Promoter is final and no correspondence or discussion will be entered into.<br />
        7.2. The Winner’s full Solana Wallet address will be announced during the draw.<br />
        7.3 The Promoter will announce the winner on any of its social media platforms as soon as practicable after the Draw Date, using the raffle number given and the Wallet address.<br />
        If the winner cannot be contacted or is not available, or has not claimed the Prize within 21 days of the Draw Date, the Promoter reserves the right to offer the Prize to another Entrant (“The Alternative Winner”) selected from the correct entries that were received before the Closing Date. Selected at random in the same method as before from the remaining correct entries received before the Closing Date. The Alternate Winner shall have 21 days from notification of their status by the Promoters to communicate their acceptance of the Prize. This process shall continue until a winner accepts the Prize.<br />
        7.4. The Promoter must either publish or make available information that indicates that a valid award took place. To comply with this obligation the Promoter will [publish the Wallet Address and amount of prize won the Website]<br />
        8.Claiming the prize<br />
        8.1. You must claim the Prize on the website/DApp. The Prize may not be claimed by a third party on your behalf. Details of how the Prize will be delivered to you (or made available for collection) are published on the Website.<br />
        8.2. If your personal details, including wallet address information, changes at any time you should notify the Promoter as soon as reasonably possible. Notifications should be sent to the Promoter via email to admin@voteforpedro.io . Notifications must include details of the competition you have entered, your old details and your new details. If your details change within 10 days of the Closing Date, the Promoter will use your old details if it needs to try to contact you.<br />
        8.3. Any Cash Prize will be transferred directly to the winners nominated Solana wallet address.<br />
        8.4. The Promoter does not accept any responsibility and is not liable to pay any compensation if you are unable to or do not take up the prize.<br />
        9.Limitation of liability<br />
        Insofar as is permitted by law, the Promoter, its agents or distributors will not in any circumstances be responsible or liable to compensate the winner or accept any liability for any loss, damage, personal injury or death occurring as a result of taking up the prize except where it is caused by the negligence of the Promoter, its agents or distributors or that of their employees. Your statutory rights are not affected.
        10.Data protection and publicity<br />
        10.1. If you are the winner of the competition, you may be required to provide further personal information and proof of your identity in order to confirm your eligibility to claim the Prize and transfer ownership of the Prize to you. You consent to the use of your information in this way. You are entitled to request further details about how your personal information is being used. You may also withdraw your consent to your personal information being used in such way but by doing so you may prevent the Prize being transferred to you. In such circumstances, you will be deemed to have withdrawn from the competition and forfeit the Prize. You will not be entitled to any refund of your entry fee. The Promoter reserves the right to offer the Prize to the next eligible Entrant selected from the correct entries that were received before the Closing Date.<br />
        10.2. Please note that under data protection laws you are entitled to request that the Promoter does not contact you and removes your details from its database. If you make such a request you will be withdrawing from the competition as it will not be possible to contact you in the event that you are the winner. You will not be entitled to any refund of any entry fee if you withdraw from the competition. If you do not wish any of your personal details to be used by the Promoter for promotional purposes, please email the Promoter at admin@voteforpedro.io prior to the Closing Date.<br />
        11.General<br />
        11.1. The Promoter reserves the right to amend these terms and conditions from time to time. The latest version of these terms and conditions will be available on the Website.<br />
        11.2. If there is any reason to believe that there has been a breach of these terms and conditions, the Promoter may, at its sole discretion, reserve the right to exclude you from participating in the competition and any future competitions.<br />
        11.3. The Promoter reserves the right to hold void, suspend, cancel, or amend the prize competition where it becomes necessary to do so. There is no minimum number of entries and the Promoter will not hold void, suspend, cancel, or amend the prize competition due to a lack of entries.<br />
        11.4. The competitions on the Website/ DApp are in no way sponsored, endorsed, administered by or associated with Facebook, Apple or Google. By entering the competitions, Entrants agree that neither Facebook, Apple nor Google have any liability and are not responsible for the administration or promotion of the competitions.<br />
        11.5. These terms and conditions shall be governed by law, and the parties submit to the exclusive jurisdiction of the courts of The United States of America.<br />
        11.6 You should print a copy of these terms and conditions and keep them for your records.<br />
      </Typography>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
