const adminNs = '/admin';

const adminRoutes = {
  HOME: `${adminNs}/`,
  RAFFLES: `${adminNs}/raffles`,
};

const routes = {
  HOME: '/',
  RAFFLES: '/raffles',
  // STAKE: '/stake',
  // TOOLS: '/tools',
  ADMIN: adminRoutes,
  WINNERS: '/winners',
  HOWTOPLAY: '/howtoplay',
  TERMSANDCONDITION: '/termsandcondition',
  ACCEPTABLEUSEPOLICY: '/acceptableusepolicy',
  PRIVACYPOLICY: '/privacypolicy',
  LEGALDISCLAIMER: 'legaldisclaimer'
};

export { routes };
