import { FC } from 'react';
import { useViewport } from '../../../hooks/useViewport';
import { Card, Typography } from '@material-ui/core';
import { DeviceType } from '../../../providers/ViewportProvider';

export interface HeaderProps {
  onBackNavigation?: () => void;
}

const Header: FC<HeaderProps> = () => {
  const { device } = useViewport();

  return (
    <div style={{ position: 'relative', width: '100%'}}>
      <img
        src={device === DeviceType.Phone ? '/hero-mobile.jpg' : '/hero.jpg'}
        alt="Hero Img"
        style={{
          width: '100%',
          marginTop: '72px',
          opacity: 0.6
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
        }}
      >
        <div
          style={{
            justifyContent: 'space-around',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            gap: '8px',
            height: '100%',
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontWeight: 'bold',
              fontSize:
                device === DeviceType.Phone
                  ? '24px'
                  : device === DeviceType.Tablet
                    ? '48px'
                    : '72px',
              color: 'white',
              textShadow:
                '0px 7px 4px rgba(95.59667358398438, 95.59667358398438, 95.59667358398438, 0.95)',
              marginBottom: '0',
            }}
          >
            Welcome to Vote For <br />
            Pedro Raffle Events
          </Typography>
        </div>
      </div>
      <Card
        style={{
          padding: '0.5rem',
          minWidth: '280px',
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4">
          Connect Wallet & Cast Your Vote For a chance to Win!
        </Typography>
      </Card>
    </div>
  );
};

export default Header;
