import { FC, HTMLAttributes, useState } from 'react';
import {
  Badge,
  Card,
  CardActions,
  CardMedia,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router';

import { Raffle } from '../../lib/types';
import { routes } from '../../router/routes';
import { getDisplayAmount } from '../../lib/accounts';
import { useStyles } from './styles';
import { useViewport } from '../../hooks/useViewport';

export interface RaffleCardProps extends HTMLAttributes<HTMLDivElement> {
  raffle: Raffle;
}

const MAX_TITLE_LENGTH = 20;

const RaffleCard: FC<RaffleCardProps> = ({
  raffle,
  className,
  ...otherProps
}) => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { push } = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  // if (raffle.prizes.length === 0) return null;

  // const prize = raffle.prizes[0];
  const prize = raffle.prize
  const imageUrl = raffle.metadata?.overviewImageUri
    // ? raffle.metadata?.overviewImageUri
    // : prize.meta?.imageUri;

  const percent = raffle.entrantsCap !== 0 ? Number(((raffle.totalTickets / raffle.entrantsCap) * 100).toFixed(1)) : 0
  return (
    <Card
      onClick={() => {
        push(`${routes.RAFFLES}/${raffle.publicKey}`);
      }}
      className={`${classes.root} ${className}`}
      {...otherProps}
    >
      <div className={classes.cardPrizesBadge}>
        {/* {raffle.prizes.length} prize{raffle.prizes.length > 1 && 's'} */}
        1 Prize
      </div>
      {raffle.isEnded && <div className={classes.cardEndedBadge}>Ended</div>}
      {!isLoaded && (
        <Skeleton
          variant="rect"
          animation={'wave'}
          classes={{
            root: classes.media,
          }}
        />
      )}
      <CardMedia
        component="img"
        className={classes.media}
        src={imageUrl}
        alt={raffle.proceeds.mint.name}
        style={isLoaded ? {} : { display: 'none' }}
        onLoad={() => setIsLoaded(true)}
      />
      <CardActions className={classes.raffleInfo}>
        <div className={classes.detailsRow1}>
          {raffle.metadata.name.length > MAX_TITLE_LENGTH ? (
            <Tooltip title={raffle.metadata.name} placement="top">
              <div>
                {raffle.metadata.name.slice(0, MAX_TITLE_LENGTH - 4)} ...
              </div>
            </Tooltip>
          ) : (
            raffle.metadata.name
          )}
        </div>
        <Badge>
          <Typography>
            {percent}% Sold
          </Typography>
        </Badge>
        <div style={{ width: '100%' }}>
          <LinearProgress
            value={Number(
              ((raffle.totalTickets / raffle.entrantsCap) * 100).toFixed(1)
            )}
            color="primary"
            style={{ height: '8px', width: '100%', borderRadius: '4px' }}
            variant="determinate"
          />
        </div>
        <div className={classes.detailsRow2} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography color='secondary' variant='h3'>{getDisplayAmount(raffle.proceeds.ticketPrice, raffle.proceeds.mint)}</Typography>
          <Typography variant='h3' color='textPrimary'>{raffle.proceeds.mint.symbol} Per Entry</Typography>
        </div>
        <div className={classes.detailsRow3}>
          <div>
            <span className={classes.cardLabel}>Total Tickets</span>
          </div>
          <div>
            <span className={classes.cardLabel}>{raffle.entrantsCap}</span>
          </div>
        </div>
        <div className={classes.detailsRow3}>
          <div>
            <span className={classes.cardLabel}>Giveaway</span>
          </div>
          <div>
            <span className={classes.cardLabel}>
              {raffle.prize.toNumber() / 1000000000} WSOL
            </span>
          </div>
        </div>
      </CardActions>
    </Card>
  );
};

export default RaffleCard;
