import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeviceType } from '../../../providers/ViewportProvider';
import { useViewport } from '../../../hooks/useViewport';

export const useStyles = makeStyles<Theme>((theme: Theme) => {
  const { device } = useViewport();
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    raffleGrid: {
      width: '100%',
      display: 'flex',
      flexDirection: device === DeviceType.Phone ? 'column' : 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    raffleGridItem: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    raffleCard: {
      width: '240px',
      height: '200px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem'
    },
    walletButtonContainer: {
      margin: '20px 0 0 0',
    },
  }
});
