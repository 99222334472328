import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Screen from '../../components/layout/Screen';
import { useStyles } from './styles';
import Spacer from '../../components/Spacer';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">
        Legal Disclaimer
      </Typography>
      <Spacer height={'20'} />
      <Typography variant="body1">
        All of the information presented in this website / dApp is tentative and may be changed at any time. None of the information herein should be interpreted as legal, accounting, or investment advice. This website / dApp is provided as-is, for informational purposes only, with the intention of describing this prospective Solana Meme / Utility Raffle system. Vote For Pedro / $VFP does not have the legal qualification of a security,since it does not give any right to dividends or interests from any corporate action, investment or funds.<br />
        The sale of Raffle entries are final and non-refundable. $VFP, Vote For Pedro & Value Focused Project Corp does not have a performance or a particular value outside the Solana Blockchain and $VFP Token Ecosystem protocol. Our Raffles shall therefore not be used or purchased for speculative or investment in any company's performance or purposes.
        The purchasers of our Raffles are aware that securities laws, which ensure that investors are sold investments that include all the proper disclosures and are subject to regulatory scrutiny for the investors' protection, are not applicable.<br />
        Anyone purchasing a Raffle expressly acknowledges and represents that
        she/he has carefully reviewed this legal disclaimer and fully understands the risks, costs and benefits associated with the purchase of our Raffles.
      </Typography>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
