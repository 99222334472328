import {
  Card,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Add } from '@material-ui/icons';
import { useViewport } from '../../../hooks/useViewport';
import { DeviceType } from '../../../providers/ViewportProvider';
import Spacer from '../../../components/Spacer';
import { routes } from '../../../router/routes';
import { useStyles } from './styles';
import AddPrizeModal from '../../../components/AddPrizeModal/AddPrizeModal';
import { Raffle } from '../../../lib/types';

const PrizeCard: FC<{ raffle: Raffle }> = ({ raffle }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { device } = useViewport();
  const theme = useTheme();
  const [prizeIsOpen, setPrizeIsOpen] = useState(false);

  return (
    <Grid
      key={raffle.publicKey.toString() + "2"}
      item
      xs={3}
      className={classes.raffleGridItem}
    >
      <Card className={classes.raffleCard}>
        <Typography variant='h4' style={{ marginBottom: '1rem' }}>{raffle.metadata.name}</Typography>
        <Spacer
          height={device === DeviceType.Phone ? '5px' : '10px'}
        />
        <Typography
          style={{ wordBreak: 'break-all', fontSize: '12px' }}
        >
          {raffle.publicKey.toBase58()}
        </Typography>
        <Spacer
          height={device === DeviceType.Phone ? '5px' : '10px'}
        />
        <Typography
          onClick={() =>
            push(`${routes.ADMIN.RAFFLES}/${raffle.publicKey}`)
          }
          style={{
            cursor: 'pointer',
            color: theme.palette.primary.main,
          }}
        >
          See Details
        </Typography>
        <Spacer
          height={device === DeviceType.Phone ? '5px' : '10px'}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Add Prize </Typography>
          <IconButton
            size={'small'}
            onClick={() => setPrizeIsOpen(true)}
          >
            <Add className={classes.scrollButtonIcon} />
          </IconButton>
        </div> */}
        <AddPrizeModal
          key={raffle.publicKey.toString()}
          isOpen={prizeIsOpen}
          setIsOpen={setPrizeIsOpen}
          raffle={raffle}
        />
      </Card>
    </Grid>
  );
};

export default PrizeCard;
