/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from './routes';
import ExploreRafflesScreen from '../pages/ExploreRafflesScreen';
import RaffleDetailsScreen from './raffleDetails';
import AdminHomeScreen from '../pages/admin/AdminHomeScreen';
import AdminRaffleScreen from '../pages/admin/AdminRaffleScreen';
import ExploreRafflesHomeScreen from '../pages/ExploreRafflesHomeScreen';
import WinnersScreen from '../pages/WinnersScreen';
import TERMSANDCONDITIONS from '../pages/Terms';
import HowToPlayScreen from '../pages/HowToPlay';
import ACCEPTABLEUSEPOLICY from '../pages/AcceptableUsePolicy';
import PRIVACYPOLICY from '../pages/PrivacyPolicy';
import LEGALDISCLAIMER from '../pages/LegamDisclamer';

export const Router: FC = () => (
  <Switch>
    <Route path="/" exact component={ExploreRafflesHomeScreen} />
    <Route path={`${routes.RAFFLES}/:id`} component={RaffleDetailsScreen} />
    <Route path={`${routes.WINNERS}`} component={WinnersScreen} />
    <Route path={routes.RAFFLES} exact component={ExploreRafflesScreen} />
    <Route path={routes.HOWTOPLAY} exact component={HowToPlayScreen} />
    <Route path={routes.TERMSANDCONDITION} exact component={TERMSANDCONDITIONS} />
    <Route path={routes.ACCEPTABLEUSEPOLICY} exact component={ACCEPTABLEUSEPOLICY} />
    <Route path={routes.PRIVACYPOLICY} exact component={PRIVACYPOLICY} />
    <Route path={routes.LEGALDISCLAIMER} exact component={LEGALDISCLAIMER} />
    {/* <Route path={routes.STAKE} exact component={StakeScreen} />
    <Route path={routes.TOOLS} exact component={ToolsScreen} /> */}
    <Route path={routes.ADMIN.HOME} exact component={AdminHomeScreen} />
    <Route
      path={`${routes.ADMIN.RAFFLES}/:id`}
      exact
      component={AdminRaffleScreen}
    />
  </Switch>
);
