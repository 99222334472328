import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DeviceType } from '../../../providers/ViewportProvider';

export const useStyles = makeStyles<Theme, { device: DeviceType }>(
  (theme: Theme,) => ({
    root: ({ device }) => ({
      display: 'flex',
      flexDirection: device === DeviceType.Phone ? 'column' : 'row',
      justifyContent: 'center',
      paddingTop: '30px',
      gap: '2rem',
    }),
    socialLink: {
      margin: '0 20px',
    },
    footerTab: ({ device }) => ({
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: device === DeviceType.Phone ? '100%' : '40%'
    }),
    NavButton: {
      justifyContent: 'center !important',
      color: 'white !important'
    }
  })
);
