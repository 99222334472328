import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useViewport } from '../../../hooks/useViewport';
import telegramLogo from '../../../assets/telegram-logo.svg';
import twitterLogo from '../../../assets/twitter-logo.svg';
import websiteLogo from '../../../assets/website-logo.svg';
import documentLogo from "../../../assets/document-logo.svg";
import emailLogo from "../../../assets/email-logo.svg";
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import NavButton from '../NavButton';
import { routes } from '../../../router/routes';

const Footer: FC = () => {
  const { device } = useViewport();
  const classes = useStyles({
    device,
  });
  const { location } = useHistory();
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.footerTab}>
          <Typography variant='h2' style={{ marginBottom: '1rem' }}>TERMS AND PRIVACY</Typography>
          <NavButton
            key='Privacy Policy'
            label='Privacy Policy'
            target={routes.PRIVACYPOLICY}
            isCurrent={location.pathname === routes.PRIVACYPOLICY}
            className={classes.NavButton}
          />
          <NavButton
            key='Acceptable use Policy'
            label='Acceptable use Policy'
            target={routes.ACCEPTABLEUSEPOLICY}
            isCurrent={location.pathname === routes.ACCEPTABLEUSEPOLICY}
            className={classes.NavButton}
          />
          <NavButton
            key='Terms & Conditions'
            label='Terms & Conditions'
            target={routes.TERMSANDCONDITION}
            isCurrent={location.pathname === routes.TERMSANDCONDITION}
            className={classes.NavButton}
          />
          <NavButton
            key='Legal Disclaimer'
            label='Legal Disclaimer'
            target={routes.HOME}
            isCurrent={location.pathname === routes.LEGALDISCLAIMER}
            className={classes.NavButton}
          />
        </div>
        <div className={classes.footerTab}>
          <Typography variant='h2' style={{ marginBottom: '1rem' }}>GENERAL INFORMATION</Typography>
          <NavButton
            key='How To Play'
            label='How To Play'
            target={routes.HOWTOPLAY}
            isCurrent={location.pathname === routes.HOWTOPLAY}
            className={classes.NavButton}
          />
          <NavButton
            key='Help & FAQs'
            label='Help & FAQs'
            target={routes.HOME}
            isCurrent={location.pathname === routes.HOME}
            className={classes.NavButton}
          />
        </div>
      </div>
      <div className={classes.root}>
        <div className={classes.footerTab}>
          <Typography variant='h2' style={{ marginBottom: '1rem' }}>For Help & FAQs</Typography>
          <NavButton
            key='Please contact us through any of the links below'
            label='Please contact us through any of the links below'
            target={routes.HOME}
            className={classes.NavButton}
          />
        </div>
      </div>
      <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', padding: '1rem' }}>
        <div className={classes.socialLink}>
          <a href="mailto:Admin@voteforpedro.io" target="_blank" rel='noreferrer'>
            <img src={emailLogo} alt={'Email logo'} width="28px" />
          </a>
        </div>
        <div className={classes.socialLink}>
          <a target="_blank" href={`https://x.com/VFP2DAY?s=20`} rel='noreferrer'>
            <img src={twitterLogo} alt={'twitter logo'} width="28px" />
          </a>
        </div>
        <div className={classes.socialLink}>

          <a target="_blank" href={`https://t.me/VoteForPedroSol`} rel='noreferrer'>
            <img src={telegramLogo} alt={'telegram logo'} width="28px" />
          </a>
        </div>
        <div className={classes.socialLink}>
          <a target="_blank" href={`https://docs.voteforpedro.io`} rel='noreferrer'>
            <img src={documentLogo} alt={'document logo'} width="28px" />
          </a>
        </div>
        <div className={classes.socialLink}>
          <a target="_blank" href={`https://voteforpedro.io`} rel='noreferrer'>
            <img src={websiteLogo} alt={'website logo'} width="28px" />
          </a>
        </div>
      </div>
      <div className={classes.root} style={{ marginBottom: '2rem' }}>
        <div className={classes.footerTab}>
          <Typography variant='body1'>Copyright ©️ VFP Token 2024</Typography>
          <Typography variant='body1'>Developed by WPT Investing Corp</Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
