import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { FC, useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import Screen from '../../../components/layout/Screen';
import WalletButton from '../../../components/layout/WalletButton';
import Spacer from '../../../components/Spacer';
import { useRafflesStore } from '../../../hooks/useRafflesStore';
import { useStyles } from './styles';
import CreateRaffleModal from '../../../components/CreateRaffleModal/CreateRaffleModal';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { isAdmin } from '../../../components/AdminRoute';
import RaffleCard from './RaffleCard';
import EndedRaffleCard from './EndedRaffleCard';

const AdminHomeScreen: FC = () => {
  const classes = useStyles();
  const { connected, publicKey } = useWallet();
  const { raffles, fetchAllRaffles } = useRafflesStore();
  const [raffleIsOpen, setRaffleIsOpen] = useState(false);
  useEffect(() => {
    fetchAllRaffles(true);
  }, [fetchAllRaffles]);

  return (
    <div className={classes.root}>
      {connected && isAdmin(publicKey) ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3">Ongoing raffles</Typography>
            <IconButton size={'small'} onClick={() => setRaffleIsOpen(true)}>
              <Add className={classes.scrollButtonIcon} />
            </IconButton>
            <CreateRaffleModal
              isOpen={raffleIsOpen}
              setIsOpen={setRaffleIsOpen}
            />
          </div>
          <Spacer height={'20px'} />
          <Grid container spacing={1} className={classes.raffleGrid}>
            {[...raffles.values()]
              .filter((raffle) => !raffle.isEnded)
              .reverse()
              .map((raffle) => (
                <RaffleCard raffle={raffle} />
              ))}
          </Grid>
          <Spacer height={'50px'} />
          <Typography variant="h3">Ended raffles</Typography>
          <Spacer height={'20px'} />
          <Grid container spacing={1} className={classes.raffleGrid}>
            {[...raffles.values()]
              .filter((raffle) => raffle.isEnded)
              .reverse()
              .map((raffle) => (
                <EndedRaffleCard raffle={raffle} />
              ))}
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="h3">
            Connect with an admin wallet to unlock admin panel
          </Typography>
          <div className={classes.walletButtonContainer}>
            <WalletButton />
          </div>
        </>
      )}
    </div>
  );
};

const AdminHomeScreenWithLayout = () => (
  <Screen>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AdminHomeScreen />
    </MuiPickersUtilsProvider>
  </Screen>
);

export default AdminHomeScreenWithLayout;
