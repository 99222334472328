import React, { FC } from 'react';
import Base from '../Base';
import Body from '../Body';
import Header from '../Header';
import Hero from '../Hero';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../router/routes';
import Footer from '../Footer';

export interface ScreenProps {
  onBackNavigation?: () => void;
}

export const Screen: FC<ScreenProps> = ({ children, onBackNavigation }) => {
  const {
    location: { pathname },
  } = useHistory();
  return (
    <Base>
      <Header onBackNavigation={onBackNavigation} />
      {pathname === routes.HOME && <Hero />}
      <Body>{children}</Body>
      <Footer />
    </Base>
  );
};

export default Screen;
