import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Screen from '../../components/layout/Screen';
import { useStyles } from './styles';
import Spacer from '../../components/Spacer';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">
        How to Play
      </Typography>
      <Spacer height={'20'} />
      <Typography variant="body1">
        Casting your Vote Raffle Entry with VFP Raffle Events couldn’t be easier! Follow the simple steps below and you could be on your way to winning one of our amazing prizes!
        <br /><br />
        Select Wallet
        <br />
        Connect & select your Solana Wallet.
        <br /><br />
        Browse Live Raffles
        <br />
        select a Raffle Event you would like to enter to win.
        <br /><br />
        Cast Your Vote
        <br />
        Select how many entries (Votes) you would like to get.
        <br /><br />
        Confirm Transaction
        <br />
        Once you have chosen your entries amount you will need to confirm transaction approval on the Solana blockchain through the connected wallet. Dapp will Mint the confirmation and Ticket/s entry confirmation can be seen on Solscan for validation and our Website.
        <br /><br />
        Winners
        <br />
        Winners will be automatically chosen via the smart contract randomizer picker and can be verified on the Solana blockchain and our website for 100% fairness and accuracy. Blockchain dont lie.
      </Typography>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
