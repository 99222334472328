import { FC, useEffect, useMemo } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useWallet } from '@solana/wallet-adapter-react';
import Screen from '../../components/layout/Screen';
import Spacer from '../../components/Spacer';
import { useRafflesStore } from '../../hooks/useRafflesStore';
import { Raffle } from '../../lib/types';
import { useStyles } from './styles';
import { expand } from '../../lib/randomnessTools';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();
  const { connected } = useWallet();
  const { raffles, fetchAllRaffles } = useRafflesStore();

  useEffect(fetchAllRaffles, [fetchAllRaffles]);

  const filterMap = useMemo(
    () => ({
      ended: (raffle: Raffle) => raffle.isEnded,
    }),
    []
  );

  const rafflesToShow = useMemo(() => {
    let toShow = [...raffles.values()].filter(filterMap.ended).filter((raffle: Raffle) => raffle.entrants.size !== 0);
    return toShow;
  }, [raffles, filterMap]);

  const rafflesToShowHasEntrants = rafflesToShow.map((value) => {
    const keys = Array.from(value.entrants.keys()).map(key => String(key));
    let winner: any = []
    if (!(!value.randomness || !value.entrants || value.entrants.size === 0)) {
      const secret = value.randomness;

      const rand = expand(secret, 0);
      const winningTicket = rand % value.totalTickets
      winner.push(value.entrantsRaw[winningTicket].toBase58());
    }
    return {
      ...value,
      keys: keys,
      winner
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="h1">
          Winners
        </Typography>
      </div>
      <Spacer height={'20px'} />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Vote</TableCell>
              <TableCell align="left">Prize</TableCell>
              <TableCell align="right">Winners</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rafflesToShowHasEntrants.map((row) => (
              <TableRow
                key={row.metadata.name}
              >
                <TableCell component="th" scope="row">
                  {row.metadata.name}
                </TableCell>
                <TableCell align="left" style={{ wordBreak: "break-word", width: "33%" }}>
                  {[...row.prizes].map((prize) => { return <>{`${prize.mint.name}`}<br /></> })}
                </TableCell>
                <TableCell align="right" style={{ wordBreak: "break-word", width: "33%" }}>
                  {console.log('row.winner',row)}
                  {row.winner.map((win: string) => { return <>{`${win.slice(0, 4) + '..' + win.slice(-4)}`}<br /></> })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
