import {
    Card,
    Grid,
    Typography,
    useTheme,
} from '@material-ui/core';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { useViewport } from '../../../hooks/useViewport';
import { DeviceType } from '../../../providers/ViewportProvider';
import Spacer from '../../../components/Spacer';
import { routes } from '../../../router/routes';
import { useStyles } from './styles';
import { Raffle } from '../../../lib/types';

const EndedRaffleCard: FC<{ raffle: Raffle }> = ({ raffle }) => {
    const classes = useStyles();
    const { push } = useHistory();
    const { device } = useViewport();
    const theme = useTheme();

    return (
        <Grid
            key={raffle.publicKey.toString()}
            item
            xs={3}
            className={classes.raffleGridItem}
        >
            <Card className={classes.raffleCard}>
                <Typography>{raffle.metadata.name}</Typography>
                <Spacer
                    height={device === DeviceType.Phone ? '5px' : '10px'}
                />
                <Typography
                    style={{ wordBreak: 'break-all', fontSize: '12px' }}
                >
                    {raffle.publicKey.toBase58()}
                </Typography>
                <Spacer
                    height={device === DeviceType.Phone ? '5px' : '10px'}
                />
                <Typography
                    onClick={() =>
                        push(`${routes.ADMIN.RAFFLES}/${raffle.publicKey}`)
                    }
                    style={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                    }}
                >
                    See Details
                </Typography>
            </Card>
        </Grid>
    );
};


export default EndedRaffleCard;
