import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';
import CountUp from 'react-countup';

import { getDisplayAmount } from '../../lib/accounts';
import { Raffle } from '../../lib/types';
import useCommonStyles from '../../assets/styles';
import { useStyles } from './styles';
import { useViewport } from '../../hooks/useViewport';

type UserTicketsDialogProps = DialogProps & {
  setOpen: (isOpen: boolean) => void;
  userTickets?: number[];
};

const UserTicketsDialog: FC<UserTicketsDialogProps> = ({
  setOpen,
  userTickets,
  ...props
}) => {
  return (
    <Dialog {...props} onClose={() => setOpen(false)} fullWidth={true}>
      <DialogTitle>My tickets</DialogTitle>
      <DialogContent>
        {userTickets?.map((userTicket) => (
          <div key={userTicket}>#{userTicket + 1}</div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

interface RaffleInfoSectionProps {
  raffle: Raffle;
  userConnected: boolean;
  userTickets?: number[];
}

const RaffleInfoSection: FC<RaffleInfoSectionProps> = ({
  userConnected,
  raffle,
  userTickets,
}) => {
  const { device } = useViewport();
  const classes = { ...useCommonStyles(), ...(useStyles({ device }) as any) };
  const [open, setOpen] = useState(false);

  const percent = raffle.entrantsCap !== 0 ? Number(((raffle.totalTickets / raffle.entrantsCap) * 100).toFixed(1)) : 0
  return (
    <div className={classes.root}>
      <div className={classes.ticketsSection}>
        <div className={classes.totalTickets}>
          <div>
            <svg
              aria-hidden="true"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
            >
              <path
                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                fill="white"
              ></path>
            </svg>
          </div>
          <Typography className={classes.label}>Tickets sold</Typography>
          <div className={classes.value} style={{ width: '100%' }}>
            <CountUp
              start={0}
              end={raffle.totalTickets}
              delay={0}
              duration={0.8}
              preserveValue
              useEasing
            >
              {({ countUpRef }) => <Typography variant="body1" color="primary" ref={countUpRef} />}
            </CountUp>
            <Typography variant="body1" color="primary" className={classes.separator}>
              /
            </Typography>
            <Typography variant="body1" color="primary">{`${raffle.entrantsCap}`}</Typography>
          </div>
        </div>
        <div className={classes.totalTickets}>
          <div>

            <svg
              aria-hidden="true"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
            >
              <path
                d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"
                fill="white"
              ></path>
            </svg>
          </div>
          <Typography className={classes.label}>
            Ticket Price
          </Typography>
          <Typography variant="body1" color="primary">
            {`${getDisplayAmount(
              raffle.proceeds.ticketPrice,
              raffle.proceeds.mint
            )} ${raffle.proceeds.mint.symbol}`}
          </Typography>
        </div>
        <div className={classes.totalTickets}>
          <div>
            <svg
              aria-hidden="true"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
            >
              <path
                d="M128 160h320v192H128V160zm400 96c0 26.51 21.49 48 48 48v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c26.51 0 48-21.49 48-48s-21.49-48-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v96c-26.51 0-48 21.49-48 48zm-48-104c0-13.255-10.745-24-24-24H120c-13.255 0-24 10.745-24 24v208c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V152z"
                fill="white"
              ></path>
            </svg>
          </div>
          <div className={classes.myTickets}>
            <Typography className={classes.label}>
              My tickets
            </Typography>
            <div className={classes.value}>
              <CountUp
                start={0}
                end={userTickets?.length ?? 0}
                delay={0}
                duration={0.8}
                preserveValue
                useEasing
              >
                {({ countUpRef }) => (
                  <Typography variant="body1" color="primary" ref={countUpRef} />
                )}
              </CountUp>
            </div>
          </div>
        </div>
      </div>
      <Badge style={{ marginTop: '1rem' }}>
        <Typography>
          {percent}% Sold
        </Typography>
      </Badge>
      <div style={{ width: '100%' }}>
        <LinearProgress
          value={Number(((raffle.totalTickets / raffle.entrantsCap) * 100).toFixed(1))}
          color="primary"
          style={{ height: '8px', width: '100%', borderRadius: '4px' }}
          variant="determinate"
        />
      </div>
      <Badge>
        <Typography color='secondary'>
          {raffle.entrantsCap - raffle.totalTickets} Tickets Left
        </Typography>
      </Badge>
      {userTickets?.length && (
        <div>
          <Button
            variant="text"
            size="small"
            disableRipple
            onClick={() => {
              setOpen(true);
            }}
            className={classes.ticketButton}
          >
            See my tickets
          </Button>
          <UserTicketsDialog
            userTickets={userTickets}
            open={open}
            setOpen={setOpen}
          />
        </div>
      )}
    </div>
  );
};

export default RaffleInfoSection;
