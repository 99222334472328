import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Screen from '../../components/layout/Screen';
import { useStyles } from './styles';
import Spacer from '../../components/Spacer';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">
      Acceptable Use Policy
      </Typography>
      <Spacer height={'20'} />
      <Typography variant="body1">
        Please Read Carefully<br /><br />
        Example additional clauses where the prize is a house, flat or other property.<br /><br />
        • All Entrants are responsible for making their own enquiries and carrying out their own due diligence relating to the Prize, their eligibility to enter the competition, their rights and eligibility to take ownership of the Prize and their eligibility to enter or live in the country where the Prize is located etc. All costs, fees or expenses incurred by the Entrant in making such enquiries or carrying out such due diligence shall be the Entrant’s sole responsibility.<br />
        • The winner of the Prize will be required to enter into a legally binding smart contract with either the Promoter or Supplier in order to transfer the legal title of the Prize to the winner. The terms of the smart contract are non-negotiable. If the winner refuses to sign or is unable to enter into any of the legal documents required to transfer ownership of the Prize to the winner within a reasonable time, the winner will be disqualified from the competition and the Prize will be forfeited. The Promoter will then be entitled to offer the prize to the next eligible Entrant selected from the correct entries that were received before the Closing Date.<br />
        • Due to the nature of the Prize, the date of transfer of ownership cannot be guaranteed but the Promoter will take all reasonable steps to complete the transfer of ownership as soon as reasonably possible.<br /><br />
        Example additional clauses where the prize is a holiday<br /><br />
        • The holiday is for 4 people to travel by aeroplane to Thailand for 14 nights between 01/03/24 – 15/03/24 . The prize does not include travel insurance, the cost of transfers to and from airports or stations, food and drink, spending money, tax or personal expenses. Any other costs incurred in addition to those set out above and that are incidental to the fulfilment of the prize are the responsibility of the winner(s).
        • You will be responsible for ensuring that you and any person travelling with you are available to travel and hold valid passports, any necessary visas and travel documents for the holiday in question on the travel dates specified. [Dates once notified to the travel agent cannot be changed].<br /><br />
        Example additional clauses where the prize is a car or other type of vehicle<br /><br />
        • If the Prize is a vehicle:<br />
        (a) the Promoter will, unless otherwise stated, ensure it comes with a valid MOT (if required); (b) no insurance is included with the Prize and it is the Winner’s responsibility to ensure the vehicle is adequately insured prior to taking it on the public roads (if it is legal to do so);  (c) the Promoter has no responsibility for the Prize once it has been delivered. The Winner is solely responsible for complying with all relevant laws and regulations relating to the vehicle, its operation and ensuring they operate it in a safe and responsible manner; (d) no vehicle/road tax is included; (e) the Winner is responsible for ensuring they have the necessary licences, qualification, knowledge and experience to operate the vehicle safely and legally; (f) the Winner is solely responsible for ensuring they have all necessary safety equipment and clothing (for example, helmets, boots and gloves) and for wearing them whilst operating the vehicle.
      </Typography>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
