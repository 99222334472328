import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Screen from '../../components/layout/Screen';
import { useStyles } from './styles';
import Spacer from '../../components/Spacer';

const AdminRaffleScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">
        Privacy Policy
      </Typography>
      <Spacer height={'20'} />
      <Typography variant="body1">
        Please Read Carefully<br />
        Introduction<br />
        Welcome to Value Focused Project Corp privacy policy.<br />
        Value Focused Project Corp respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we process and look after your personal data when you visit our website (regardless of where you visit it from).<br />
        It also tells you about your privacy rights and how the law protects you.<br />
        Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.<br />
        Important information and who we are<br />
        Purpose of this privacy policy<br />
        This privacy policy aims to give you information on how Value Focused Project Corp collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter, purchase any products or services or take part in a prize draw or competition.<br />
        This website is not intended for children and we do not knowingly collect data relating to children.<br />
        It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.<br />
        Controller<br />
        Value Focused Project Corp is the controller and responsible for your personal data (collectively referred to as Value Focused Project Corp “we”, “us” or “our” in this privacy policy).<br />
        We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.<br />
        Contact details<br />
        If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager in the following ways:
        Admin@voteforpedro.io<br />
        Georgia, The United States Of America.<br />
        Changes to the privacy policy and your duty to inform us of changes<br />
        We keep our privacy policy under regular review. This version was last updated on 05/20/2024.<br />
        It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.<br />
        Third-party links<br />
        This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.<br />
        The data we collect about you<br />
        Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).<br />
        Technical Data includes [internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website].<br />
        Profile Data includes [your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses].<br />
        Usage Data includes [information about how you use our website, products and services].<br />
        Marketing and Communications Data includes [your preferences in receiving marketing from us and our third parties and your communication preferences].
        However, if you do not wish to participate in any publicity and decline to be named as the winner, we may still have to provide your details to the Advertising Standards Authority (ASA) as proof that the competition has been properly administered and the prize awarded. This is a legal requirement we must comply with.<br />
        If you require detail of how the ASA will use and process your personal data, please let us know.<br />
        Marketing<br />
        We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.<br />
        Promotional offers from us.<br />
        Third-party marketing<br />
        We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.<br />
        Opting out<br />
        You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.<br />
        Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.<br />
        Cookies<br />
        You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see CLICK HERE<br />
        Change of purpose.<br />
        Your legal rights<br />
        Under certain circumstances, you have rights under data protection laws in relation to your personal data.<br />
        If you wish to exercise any of these rights, please contact us.<br />
        No fee usually required<br />
        You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.<br />
        Time limit to respond<br />
        We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.<br />
      </Typography>
    </div>
  );
};

const AdminRaffleScreenWithLayout = () => (
  <Screen>
    <AdminRaffleScreen />
  </Screen>
);

export default AdminRaffleScreenWithLayout;
