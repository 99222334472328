import { FC, useState } from 'react';
import { AppBar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useWallet } from '@solana/wallet-adapter-react';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import { routes } from '../../../router/routes';
import AirdropButton from '../../AirdropButton';
import { useViewport } from '../../../hooks/useViewport';
import WalletButton from '../WalletButton';
import NavButton from '../NavButton';
import { TESTING } from '../../../config/misc';
import { isAdmin } from '../../AdminRoute';
import Drawer from '../Drawer';
import { DeviceType } from '../../../providers/ViewportProvider';

export interface HeaderProps {
  onBackNavigation?: () => void;
}

const NAV_LINKS_LIST = [
  { label: '🏠 HOME', target: routes.HOME },
  { label: '🏆 Winners', target: routes.WINNERS },
  { label: '🗳️ Vote Raffles', target: routes.RAFFLES },
  { label: '🌐 PROJECT WEBSITE', target: 'https://voteforpedro.io' },
  { label: '🔑 Admin', target: routes.ADMIN.HOME, admin: true }
];

const Header: FC<HeaderProps> = ({ onBackNavigation }) => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { connected, publicKey } = useWallet();
  const { push, location } = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div
        style={{
          width: '100%',
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))',
          backdropFilter: 'blur(3px)',
          zIndex: 98,
        }}
      />
      <AppBar className={classes.appBar} elevation={0}>
        {device === DeviceType.Phone ? (
          <div className={classes.drawerHeader}>
            <div style={{ display: 'flex' }}>
              <IconButton size={'medium'} onClick={() => setIsDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
              <IconButton size={'medium'} onClick={() => push(routes.HOME)} className={classes.homeButton}>
                <img src='/logo.jpg' alt='logo' height='48px' width='48px' />
              </IconButton>
            </div>
            <Drawer
              wallet={publicKey}
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              navLinksList={NAV_LINKS_LIST}
            />
            <div className={classes.walletButtonContainer}>
              <WalletButton />
            </div>
          </div>
        ) : (
          <>
            <div>
              <IconButton
                onClick={() => push(routes.HOME)}
                className={classes.homeButton}
              >
                <img
                  src="/logo.jpg"
                  alt={'Site banner'}
                  className={classes.homeButtonIcon}
                />
              </IconButton>
            </div>
            <div className={classes.navContainer}>
              {NAV_LINKS_LIST.filter(
                ({ admin }) => !admin || (admin && isAdmin(publicKey))
              ).map(({ label, target }) => (
                target.includes('https') ? (
                  <a
                    key={target}
                    href={target}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.navLinks}
                  >{label}</a>
                ) : (
                  <NavButton
                    key={target}
                    label={label}
                    target={target}
                    isCurrent={location.pathname === target}
                  />
                )

              ))}
              {connected && TESTING && <AirdropButton />}
              <div className={classes.walletButtonContainer}>
                <WalletButton />
              </div>
            </div>
          </>
        )}
      </AppBar>
    </div>
  );
};

export default Header;
